import Cart from 'Components/Workflow/Cart';
import DefaultLayout from 'Components/Workflow/DefaultLayout';
import Headline from 'Components/Workflow/Headline';
import Nav from 'Components/Workflow/Nav';
import { conditionalStyles } from 'Helpers/conditionalStyles';
import { observer } from 'mobx-react';
import { AddonTrain } from 'Models/UI/AddonTrain';
import { PayloadTrain } from 'Models/UI/PayloadTrain';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TiArrowRight } from 'react-icons/ti';
import { Element as ScrollTo, scroller } from 'react-scroll';
import { Alert, Button, Collapse } from 'reactstrap';
import app from 'State/AppService';
import errorHandler from 'State/ErrorHandler';
import workflow from 'State/WorkflowService';
import { Keys } from 'Translation/Setup';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import { Box } from './Components/Box';
import { Info } from './Components/Info';
import { TrainConnection } from './Components/TrainConnection';
import { TrainSelection } from './Components/TrainSelection';
import iconDB from './Icons/db.png';
import styles from './Train.module.scss';
import { TrainState, trainState } from './TrainState';
import { withTheme } from '../../../Context/withTheme';
import { ThemeProps } from '../../../Context/AppContext';
import {useLanguage} from "../../../Helpers/useLanguage";
import { TrainTariff } from './Components/TrainTariff';

const css = conditionalStyles(styles);

const Train = observer(function Train(props: ThemeProps) {
  const state = useContext<TrainState>(trainState);
  const { t, i18n } = useTranslation();
  const iframeSrcLanguage = useLanguage();

  const [trainReadMore, setTrainReadMore] = React.useState(false);

  const scrollElementPrefix = 'scrollElement_';

  const trainFakeBox = css('train', 'train--fullWidth', 'train--isSelected', {});

  useEffect(() => {
    if (
      workflow.isCurrentStepExcluded ||
      (workflow.booking && workflow.booking.ritMode !== 'RIT') ||
      props.theme === 'pepxcite'
    ) {
      workflow.excludeCurrentStep();
      workflow.navigateToNextStep(true);
      return;
    }
    app.trackTimeLoadStarted(workflow.currentStep!);
    workflow
      .loadStep()
      .then(({language}) => {
        const globalLanguage = iframeSrcLanguage || language;
        if (!!globalLanguage) {
          i18n.changeLanguage(globalLanguage);
        }
        state.readyNow();
        app.deactivateLoader();
      })
      .then(() => app.trackTimeLoadFinished(workflow.currentStep!))
      .then(() => {
        app.tracking.addPageAndUser(workflow.booking);
        if (workflow.currentStepIndex === 1) {
          app.tracking.sendBeginCheckoutEvent(workflow.booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes);
        } else {
          app.tracking.sendCheckoutStepEvent(workflow.booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes);
        }
        return;
      })
      .then(() => {
        console.log('STEPINDEX', workflow.currentStepIndex)
        if (state.train) {
          toggleItem(state.train as AddonTrain);
        } else if (state.none) {
          toggleItem(state.none as AddonTrain);
        } else if (state.itemGroup && state.itemGroup.items && state.itemGroup.items.length > 0) {
          toggleItem(state.itemGroup.items[state.itemGroup.items.length - 1] as AddonTrain);
        }
      })
      .then(() => window.scrollTo(0, 0))
      .catch(e => errorHandler.catchError(e));

    getTrainStation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Breadcrumbs next={() => next()} />
      <DefaultLayout
        top={renderTop()}
        left={renderLeft()}
        right={renderRight()}
        bottom={renderBottom()}
      />
    </>
  );

  function fetchTrainStation(): Promise<any> {
    const myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    myHeaders.append('Content-Type', 'application/json; charset=utf-8');

    const myInit = {
      method: 'GET',
      headers: myHeaders
    };
    return fetch('/trains.json', myInit);
  }

  async function getTrainStation() {
    let response = await fetchTrainStation();
    const trainStations = await response.json();
    state.setTrainStations(trainStations);
  }

  function hasError(train: AddonTrain) {
    const itemError = state.itemErrorMessages.hasOwnProperty(train.id)
      ? state.itemErrorMessages[train.id]
      : false;
    return !!itemError;
  }

  function renderLeft() {
    return (
        <div className={styles.container}>
          <p>
            <strong>{t(Keys.WORKFLOW.Train.intro.title)}</strong>
          </p>

          <p>
            <Trans i18nKey={Keys.WORKFLOW.Train.intro.text}></Trans>
          </p>

          <div className='topNav--mobile'>
            <div className='addBtn'>
              <Button
                  color="primary"
                  onClick={() => addTrain()}
                  tabIndex={0}
                  onKeyPress={e => e.key === 'Enter' && next()}
                  className={''}
                  block
              >
                <div>
                  <span>{t(Keys.COMPONENTS.Mobile.Nav.add)}</span>
                </div>
              </Button>
            </div>
            <div className='nextBtn'>
              <Button
                  color="primary"
                  onClick={() => continueWithoutTrain()}
                  tabIndex={0}
                  onKeyPress={e => e.key === 'Enter' && next()}
                  className={'test'}
                  block
              >
                <div>
                  <span>{t(Keys.COMPONENTS.Mobile.Nav.next)}</span>
                </div>
              </Button>
            </div>
          </div>
          <div className={styles.trains}>
            {state.train && (
                <React.Fragment>
                  {state.showValidation && (
                      <div className={styles.errorContainer}>
                        <ScrollTo name={scrollElementPrefix + state.train.id}>
                          <Alert color="danger" isOpen={state.showValidation && hasError(state.train)}>
                            {t(Keys.WORKFLOW.Train.itemError)}
                            <div className={styles.small}>{/* <div>{getError(state.train)}</div> */}</div>
                          </Alert>
                        </ScrollTo>
                      </div>
                  )}

                  <Box
                      item={state.train}
                      isSelected={isTrainSelected(state.train)}
                      selectedItem={state.selectedTrain}
                      label={t(Keys.WORKFLOW.Train.train.label)}
                      toggle={toggleItem}
                  >
                    <Info url={state.train.infoURL}/>

                    <div className={styles.trainDescription} onClick={() => toggleItem(state.train!)}>
                      <h5>{state.train.title}</h5>
                      {state.train.descriptionDetail && (
                          <div className={styles.trainDescriptionHtml}>
                            <ul>
                              {state.train.descriptionDetail.map((ele, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={css({
                                          'd-none': index > 2 && !trainReadMore
                                        })}
                                    >
                                      {ele}
                                    </li>
                                );
                              })}
                            </ul>
                            <button
                                className={styles.info}
                                onClick={e => {
                                  setTrainReadMore(!trainReadMore);
                                  e.stopPropagation();
                                }}
                            >
                              {!trainReadMore && t(Keys.WORKFLOW.Train.readMore)}
                              {trainReadMore && t(Keys.WORKFLOW.Train.readLess)}
                            </button>
                          </div>
                      )}
                      <Collapse isOpen={isTrainSelected(state.train)}>
                        <div className={styles.trainConnection}>
                          <TrainSelection
                              direction={state.direction}
                              onChangeDepartingStationCode={value => {
                                state.setDepartingStationCode(value);
                                onChangeTrainSelection();
                              }}
                              onChangeDepartingClassCode={value => {
                                state.setDepartingClassCode(value);
                                onChangeTrainSelection();
                              }}

                              onChangeDestinationCode={value => {
                                state.setDestinationCode(value);
                                onChangeTrainSelection();
                              }}

                              onChangeReturningStationCode={value => {
                                state.setReturingStationCode(value);
                                onChangeTrainSelection();
                              }}

                              onChangeReturningClassCode={value => {
                                state.setReturingClassCode(value);
                                onChangeTrainSelection();
                              }}
                              onChangeDirection={value => {
                                state.setDirection(value);
                                onChangeTrainSelection();
                              }}
                              departingStationCode={state.departingStationCodeLabel}
                              departingClassCode={state.departingClassCode}
                              returningStationCode={state.returningStationCodeLabel}
                              returningClassCode={state.returningClassCode}
                              destination={state.destination}
                              destinationLabel={state.destinationLabel}
                              trainConnections={state.trainStations}
                          />

                          {!!state.selectedTrain &&
                              (state.selectedTrain.departingSelection.hasStepOne ||
                                  state.selectedTrain.returningSelection.hasStepOne) &&
                                  (state.selectedTrain.destination !== null) && (<>
                                <TrainConnection
                                    id={'train'}
                                    direction={state.direction}
                                    departingTrains={state.departingTrains}
                                    returningTrains={state.returningTrains}
                                    destination={state.destination}
                                    destinationLabel={state.destinationLabel}
                                    departureDate={state.departureDate}
                                    departingStation={state.departingStationCodeLabel}
                                    returningStation={state.returningStationCodeLabel}
                                    returningDate={state.arrivalDate}
                                    selectedDepartingTrain={state.departingTrain}
                                    selectedDepartingSearchHour={state.departingSearchHour}
                                    selectedReturningSearchHour={state.returningSearchHour}
                                    onChangeDepartingTrain={value => {
                                      state.setDepartingTrain(value);
                                      onChangeTrainConnection();
                                    }}
                                    onChangeDepartingSearchHour={value => {
                                      state.setDepartingSearchHour(value);
                                      onChangeTrainConnection();
                                    }}
                                    selectedReturningTrain={state.returningTrain}
                                    onChangeReturningTrain={value => {
                                      state.setReturningTrain(value);
                                      onChangeTrainConnection();
                                    }}
                                    onChangeReturningSearchHour={value => {
                                      state.setReturningSearchHour(value);
                                      onChangeTrainConnection();
                                    }}
                                />
                                {state.bundles &&
                                    <TrainTariff
                                        bundles={state.bundles}
                                        selectedBundle={state.selectedBundle}
                                        onChangeSelectedBundle={bundle => {
                                          state.setSelectedBundle(bundle);
                                          onChangeTrainConnection();
                                        }}
                                    />}
                              </>)}
                        </div>
                        <div className={styles.seperator}></div>
                      </Collapse>
                    </div>
                  </Box>
                </React.Fragment>
            )}

            {state.showNoneItem && (
                <Box
                    selectedItem={null}
                    item={state.none}
                    isSelected={isTrainSelected(state.none)}
                    label={t(Keys.WORKFLOW.Train.none.label)}
                    toggle={toggleItem}
                >
                  <div className={styles.trainDescription} onClick={() => toggleItem(state.none!)}>
                    <h5>{state.none.title}</h5>
                  </div>
                </Box>
            )}

            {state.late && (
                <Box
                    item={state.late}
                    selectedItem={null}
                    isSelected={isTrainSelected(state.late)}
                    label={t(Keys.WORKFLOW.Train.late.label)}
                    toggle={toggleItem}
                >
                  <Info url={state.late.infoURL}/>
                  <div className={styles.trainDescription} onClick={() => toggleItem(state.late!)}>
                    <h5>{state.late.title}</h5>

                    <div
                        dangerouslySetInnerHTML={{
                          __html: state.late.description
                        }}
                    />
                    {state.late.descriptionDetail && (
                        <div className={styles.trainDescriptionHtml}>
                          <ul>
                            {state.late.descriptionDetail.map((ele, index) => {
                              return <li key={index}>{ele}</li>;
                            })}
                          </ul>
                        </div>
                    )}
                  </div>
                </Box>
            )}
            {/* gruppenbuchung */}
            {state.group && (
                <div className={trainFakeBox}>
                  <div className={styles.trainDescription}>
                    <h5>{state.group.title}</h5>

                    <div
                        dangerouslySetInnerHTML={{
                          __html: state.group.description
                        }}
                    />
                  </div>
                </div>
            )}
          </div>
        </div>
    );
  }

  function renderTop() {
    return (
        <div className={'d-flex justify-content-between'}>
          <div className={styles.headlineContainer}>
            <div className={styles.headlineText}>
              <Headline text={t(Keys.WORKFLOW.Train.headline)}/>
            </div>
            <div className={styles.headlineImageContainer} title={'Deutsche Bahn'}>
              <img className={styles.headlineImage} alt={'Deutsche Bahn'} src={iconDB} />
          </div>
        </div>
        <div className={styles.topNav}>
          <Button
            color="primary"
            onClick={() => next()}
            tabIndex={0}
            onKeyPress={e => e.key === 'Enter' && next()}
            className={'w-100'}
            block
          >
            <div>
              <span>{t(Keys.COMPONENTS.Nav.next)}</span>
              <TiArrowRight />
            </div>
          </Button>
        </div>
      </div>
    );
  }

  function renderRight() {
    return <Cart bookingOnRequest={workflow.bookingOnRequest} />;
  }

  function renderBottom() {
    return (
      <>
        <Alert color="danger" isOpen={state.showValidation && state.showSelectionErrorMessages}>
          {t(Keys.WORKFLOW.Train.selectionError)}
        </Alert>
        <Alert color="danger" isOpen={state.showValidation && state.hasItemErrors}>
          {t(Keys.WORKFLOW.Train.globalFormError)}
        </Alert>

        <Alert color="danger" isOpen={state.showValidation && state.apiErrorMessage !== null}>
          <strong>{t(Keys.WORKFLOW.Train.apiError)} </strong>
          <br />
          {state.apiErrorMessage}
        </Alert>

        <Nav onCloseWindow={() => window.top?.close()} onNext={() => next()} />
      </>
    );
  }

  function scrollToFirstError() {
    if (state.hasItemErrors) {
      scroller.scrollTo(scrollElementPrefix + state.firstErrorId, {
        duration: 750,
        smooth: true,
        offset: -30
      });
    }
  }

  async function next() {
    state.updateShowValidation(true);

    state.validateSelectedItems();

    if (state.hasItemErrors || state.showSelectionErrorMessages) {
      state.updateShowValidation(true);
      window.setTimeout(scrollToFirstError, 100); //TODO fix this shit
      return;
    }

    app.activateLoader();

    try {
      await saveStep();
      workflow.navigateToNextStep();
      app.deactivateLoader();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
      app.deactivateLoader();
    }
  }

  function addTrain() {
    toggleItem(state.train!);
    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });
  }

  function continueWithoutTrain() {
    toggleItem(state.none!);
    next();
  }

  function toggleItem(item: AddonTrain) {
    if (
      state.selectedTrain === null ||
      (!!state.selectedTrain && state.selectedTrain.id !== item.id)
    ) {
      if (!!state.train && item.id !== state.train.id) {
        setTrainReadMore(false);
      }

      state.removeAllErrors();

      state.selectedItems.forEach(i => workflow.removeItem(i.id));
      state.setTrain(item);

      if (
        (!!state.none && item.id === state.none.id) ||
        (!!state.group && item.id === state.group.id)
      ) {
        setTrainToWorkflow(item);
      }
    }
  }

  function isTrainSelected(train: AddonTrain): boolean {
    return !!(state.selectedTrain && state.selectedTrain.id === train.id);
  }

  function onChangeTrainSelection() {
    const train = state.getTrain();
    if (
      !!train &&
      ((!!train.departingSelection && train.departingSelection.hasStepOne) ||
        (!!train.returningSelection && train.returningSelection.hasStepOne))
    ) {
      setTrainToWorkflow(train);
    }
  }

  function onChangeTrainConnection() {
    const train = state.getTrain();
    if (
      !!train &&
      ((!!train.departingSelection &&
        train.departingSelection.hasStepOne &&
        train.departingSelection.hasStepTwo) ||
        (!!train.returningSelection &&
          train.returningSelection.hasStepOne &&
          train.returningSelection.hasStepTwo))
    ) {
      setTrainToWorkflow(train);
    }
  }

  async function setTrainToWorkflow(item: AddonTrain) {
    app.activateLoader();

    state.removeItemError(item);

    state.selectedItems.forEach(i => workflow.removeItem(i.id));

    workflow.addItem<AddonTrain>(AddonTrain, item.id);

    try {
      await saveStep();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
    }
    app.deactivateLoader();
  }

  async function saveStep() {
    app.trackTimeSaveStarted(workflow);
    state.updateApiErrorMessage(null);

    const payload = new PayloadTrain();
    payload.train = state.getTrain() as AddonTrain;

    if (payload.train) {
      payload.train.destination = state.destinationCode;
    }

    await workflow.saveStep(payload).then(() => {
      if (state.selectedItems.length > 0) {
        console.log("#### SELECTED ITEMS: ", state.selectedItems);
        
        state.setTrain(state.selectedItems[0] as AddonTrain);
      }
    });

    await app.trackTimeSaveFinished(workflow);
  }
});
export default withTheme(Train);
