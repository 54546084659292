import { classToPlain } from 'class-transformer';
import {load, loadNoScriptIframe} from "./File";
import isEmpty          from 'validator/lib/isEmpty';

declare global {
    interface Window {
        dataLayer : any[];
    }
}

export interface GtmDataLayerEvent {
    event? : string;

    [ key : string ] : any;
}

export class GtmDataLayer {

    private dataLayer : GtmDataLayerEvent[];
    private gtmParams : string;

    constructor( gtmParams : string ) {
        window.dataLayer = window.dataLayer || [];
        this.dataLayer   = window.dataLayer; // copy by ref!
        this.gtmParams   = gtmParams && !isEmpty( gtmParams ) ? gtmParams : '';
        this.init();
    }

    private init() {
        if ( this.gtmParams ) {
            this.push( {
                'event'     : 'gtm.js',
                'gtm.start' : new Date().getTime()
            } );
            load( `https://sst.pepxpress.com/gtm.js?${ this.gtmParams }` );
            //loadNoScriptIframe('https://www.googletagmanager.com/ns.html?id=GTM-N4MLQB');
        } else {
            console.warn( 'Tracking is not implemented in this environment.' );
        }
    }

    public push<E extends {}>( event : E ) {
        const plainData = classToPlain<E>( event ) as GtmDataLayerEvent;
        console.log( 'GtmDataLayer', plainData )
        this.dataLayer.push( plainData );
    }

    public getDataLayer() {
        return this.dataLayer;
    }

    public clearDataLayer() {
        this.dataLayer.push({'ecommerce': null});
    }
}
